body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Kanit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Kanit';
}


.accordion-item{
  border: none !important;
}


.slick-arrow{
  color: transparent !important;
}



.eventSlider {
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease 0.2s;
  -moz-animation: fadeIn ease 0.2s;
  -o-animation: fadeIn ease 0.2s;
  -ms-animation: fadeIn ease 0.2s;
  margin-bottom: 100px;
}

.eventSlider:hover .slick-prev:before {
  display: block;
}

.eventSlider:hover .slick-next:before {
  display: block;
}

/* .eventSlider .slick-prev:before {
  display: none;
  content: url(./Assets/Home/prevBtn.svg) !important;
  font-size: 50px !important;
}

.eventSlider .slick-next:before {
  display: none;
  content: url(./Assets//Home/nextBtn.svg) !important;
  font-size: 50px !important;
} */

.slick-arrow .slick-prev button {
  width: 500px !important;
  height: 50px !important;
}

.slick-arrow .slick-prev {
  visibility: hidden;
}

.slick-prev {
  position: absolute !important;
  width: 50px !important;
  height: 50px !important;
  left: 4% !important;
  top: 20% !important;
  z-index: 1;
  border: none;
  background: transparent;
}

.slick-next {
  position: absolute !important;
  width: 50px !important;
  height: 50px !important;
  right: 6% !important;
  top: 20% !important;
  border: none;
  background: transparent;
}

.slick-prev:before {
  content: url(./Assets/Home/prevBtn.svg) !important;
  font-size: 50px !important;
}

.slick-next:before {
  content: url(./Assets//Home/nextBtn.svg) !important;
  font-size: 50px !important;
}


.slick-dots{
  display: none !important;
}

@media screen and (max-width: 576px) {
.slick-dots{
  display: block !important;
  top: 65% !important;
}
}